.message-list-container {
  padding: 10px;
  padding-right: 0px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 83px);
}
.message-list-container{

    scrollbar-width: 'thin';
    scrollbar-color: transparent transparent;
}
.message-list-container::-webkit-scrollbar {
  width: 8px;
}

.message-list-container::-webkit-scrollbar-track {
  background: transparent;
}

.message-list-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;

}
.training-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 12px;
  border: solid 2px black;
  max-width: 350px;
  flex: 1;
  align-items: center;
  border-radius: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-size: cover !important;
  position: relative;
}

.training-container span {
  color: white;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  z-index: 1;
}

.training-missed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 12px;
  border: solid 2px black;
  max-width: 350px;
  flex: 1;
  align-items: center;
  border-radius: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.training-missed-container span {
  color: black;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

.training-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #707070;
  flex: 1;
  max-width: 650px;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.training-summary-container span {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

.progress-container {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 450px;
  height: 7px;
  border-radius: 4px;
  background-color: #E0E0E0;
}

.progress-container > div {
  width: 40%;
  height: 100%;
  border-radius: 4px;
  background-color: #2B96F2;
}
