.MuiIconButton-edgeEnd{
  margin-right:0px!important   
}

.MuiAccordionSummary-content {
  flex-direction: row;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0!important;
}
.MuiAccordionSummary-root .MuiIconButton-root{
padding: 0px
}
.MuiAccordionSummary-expandIcon{
  transform: rotate(-90deg)!important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded{
  transform: rotate(0deg)!important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px!important;
}
.MuiAccordionDetails-root{
  align-items: center;
}
.MuiAccordionSummary-root{
  padding: 0px!important;
}
.MuiCollapse-wrapperInner {
  background: white;
}
.MuiAvatar-colorDefault {
  margin-right: 8px;
}
#drawer .MuiAvatar-root {
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
}
.conversation-warning{
  font-size: 12px;
  color: red;
  margin: 0;
}
.MuiAccordionDetails-root:first-child{
  border-top: 1px solid #dddddd;

}