.MuiIconButton-edgeEnd{
  margin-right:0px!important   
}

.MuiAccordionSummary-content {
  flex-direction: column;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0!important;
}
.MuiAccordionSummary-root .MuiIconButton-root{
padding: 12px 12px 12px 12px
}
.MuiAccordionSummary-expandIcon{
  transform: rotate(-90deg)!important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded{
  transform: rotate(0deg)!important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px!important;
}
.MuiAccordionDetails-root{
  padding: 0px!important;
 flex-direction: column;
 align-items: normal!important;
}
.MuiCollapse-wrapperInner {
  background: white;
}
.MuiAvatar-colorDefault {
  margin-right: 8px;
}
#drawer .MuiAvatar-root {
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
}
#drawer:hover{
  background-color: #eeeef1;

}
.conversation-warning{
  font-size: 12px;
  color: red;
  margin: 0;
}