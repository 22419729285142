 .flex{
  display: flex;
 }
.mainContainer{
  width: 100%;
  padding:30px 30px 0px 30px;
}
.rowWrapper{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.boxSpacer{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.box{
  padding: 15px;
  position: relative;
  display: flex;
  height: 96%;
  background-color: white;
  flex-direction: column;
  word-wrap: break-word;
  margin-bottom: 20px;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;
}
.col-70{
  flex: 0 0 70%;
  max-width: 70%;
}
.col-30{
  flex: 0 0 30%;
  max-width: 30%;
}
.col-50{
  flex: 0 0 50%;
  max-width: 50%;
}
.col-100{
  flex: 0 0 100%;
  max-width: 100%;
}
.pl-15{
  padding-left: 25px;
}
 
#girddata td {
  padding: 7px;
}
#girddata tr td:first-child {
  text-align: start!important;
}
#girddata tr:first-child td:not(:first-child) {
  width: 280px;
  word-break: break-word;
}
#girddata tr:nth-child(2) td:not(:first-child),
#girddata tr:nth-child(3) td:not(:first-child) {
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
}
#girddata tr:first-child td,
#girddata tr td:first-child {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
#girddata tr:first-child td {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.tabs {
  width:100%;
}
.vertical .tabs-stage{
  width: 100%;
  
}
.tabs a {
  padding-left: 25px!important;
}
.vertical .tabs-nav{
  width: 60%;
}
.tabs-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.vertical  .tabs-nav li{
  width: 100%;
}
.vertical  .tabs-nav li h3{
  margin:15px 10px 20px 0px;
}
.tabs-nav li {
  float: left;
  width: 33.33%;
}
.tabs-nav a  {
  color:#8B8B8B!important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.tabs-nav a>h3  {
  color:black!important;
  font-size: 22px;
  font-weight: 600;
}
.vertical.tabs a{

  padding: 10px 25px 10px 25px;
  border-top: none;
  border-bottom: none;
  border-right: 1px solid #D6D6D6;
}
.tabs a {
  border: 1px solid transparent;
  color: black;
  cursor: pointer;
  display: block;
  padding: 10px 0;
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  text-decoration: none;
  transition: border-bottom-color 0.3s ease-in-out;
}

a:hover {
  color: #44a1f1;
}
.vertical .tab-active a {
  border-right: 1px solid black;
  background: #e7e7e7;
}
.tab-active a {
  background: #fff;
  border-bottom: 1px solid black;
  color: black;
  cursor: default;
}

#style-1 ::-webkit-scrollbar {
  width: 6px;
}

#style-1 ::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 10px;
}
 
li:hover {
  cursor: default;
}
.list {
  width: 100%;
  position: relative;
  float: left;
  display: inline-block;
  background-color: transparent;
  border-radius: 5px;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke:#02FFFE
}
.CircularProgressbar .CircularProgressbar-text {
  fill:black
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke:#F7F7F7;
}
.vertical .list ul {
  max-height: 455px;
  height: 100%;
}
.list ul {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 290px;
}
.tabs-nav .list li:hover {
background: #eeeef1;
}
.list li {
  list-style: none;
  padding-left: 0px;
  cursor: pointer;  
}
.user-wrapper li{
  max-width: 80%;
  width: 100%;
}
@media (max-width: 1590px){
  .vertical .list ul {
  max-height: 463px;
  }
  .list ul {
    max-height: 295px;
  }
}
@media (max-width: 1200px){
  .rowWrapper{
    display: block;
  }
  .col-70,
  .col-50,
  .col-30{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .user-wrapper li{
    max-width: 70%;
 
  }
  
}
@media (max-width: 760px){
  #girddata tr:nth-child(2) td:not(:first-child),
  #girddata tr:nth-child(3) td:not(:first-child) {
    font-weight: 400;
    font-size: 24px;
  }
  #girddata tr:first-child td,
  #girddata tr td:first-child {
    font-size: 12px;
  }
  #girddata tr:first-child td {
    font-weight: 300;
  }
}

