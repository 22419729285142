.trainingModal {
  width: 100%;
}

.MuiDialog-paperWidthSm {
  width: 720px;
}

.audios-library {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.audios-library-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audios-library-loading>span {
  font-size: 14px;
}

.audios-library-header {
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
}

.audios-library-header h3 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.audios-library-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f5f5f5;
}

.audios-library-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding: 6px 10px;
  cursor: pointer;
}

.audios-library-item:hover {
  background-color: #e1e1e1;
}

.audios-library-item h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
}

.audios-library-item span {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.audios-timestamps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.traningBox {
  padding: 15px 0px 0px 0px;
}

.trainingTabs {
  width: 100%;
}

.trainingTabs a {
  padding-left: 8px !important;
}

.trainingTabs-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.trainingTabs .list ul,
.wrappertrainingTabs .list ul {
  min-height: 55vh;
}

.exerciceAdd .trainingTabs .list ul {
  min-height: 70vh;
}

.wrappertrainingTabs .list {
  min-height: 55vh;
}

.trainingTabs-nav li {
  float: left;
  width: 33.33%;
  display: flex;
  align-items: center;
  padding-left: 10px !important;
}

.trainingModal li:hover {
  cursor: pointer;
}

.trainingTabs-nav a {
  font-size: 14px;
  font-weight: 600;
}

.trainingTab-active a:hover {
  color: black !important;
}

.trainingTabs-nav>li {
  border: 1px solid transparent;
  color: black;
  padding: 10px 0;
  border-bottom: 2px solid #d6d6d6;
  text-decoration: none;
  transition: border-bottom-color 0.3s ease-in-out;
}

li.trainingTab-active {
  background: #fff;
  border-bottom: 2px solid rgb(0, 21, 255);
  color: black;
  cursor: default !important;
}

.list ul:has([role="progressbar"]) {
  margin: 15px;
  display: flex;
  justify-content: center;
  scrollbar-width: "thin" !important;
  scrollbar-color: transparent transparent !important;
}

.list ul:has([role="progressbar"])::-webkit-scrollbar {
  width: 0px !important;
}

.list ul:has([role="progressbar"])::-webkit-scrollbar-track {
  background: transparent !important;
}

.trainingBtngroup {
  border-top: 2px solid #d6d6d6;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: flex-end;
}

.trainingBtngroup Button {
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-radius: 4px;
  line-height: 1;
  font-size: 12px;
  border: 1px solid #d6d6d6;
}

.trainingBtngroup #cancel {
  margin-right: 15px;
}

.trainingBtngroup #confirm-off,
.trainingBtngroup #delete-off {
  background-color: #d6d6d6;
  border: 0px solid none;
  color: white;
}

.trainingBtngroup #confirm-on:not(:disabled) {
  background-color: #0080f6;
  border: 0px solid none;
  color: white;
}

.trainingBtngroup #delete-on {
  background-color: #e94444;
  border: 0px solid none;
  color: white;
}

#delete-on,
#delete-off {
  margin-right: 15px;
}

.choosenTemplate {
  background-color: #0080f6;
}

.trainingModal .nameGroup {
  display: flex;
  flex-direction: column;
  margin: 7px 0px;
  padding: 5px;
}

.trainingModal .trainingBtngroup Button {
  border: none;
  font-weight: 500;
  padding: 10px 25px;
  text-transform: initial;
}

.trainingModal .MuiDialogActions-root {
  padding: 0px;
}

.trainingModal {
  overflow: hidden;
}

.trainingModal .choosenTemplate .nameGroup {
  color: white;
}

.trainingModal .wrapper {
  padding: 0px 28px;

  .MuiOutlinedInput-adornedStart {
    padding-left: 10px;
  }
}

.trainingModal .trainingTabs {
  padding: 0px 28px;
}

.trainingBtngroup #confirm-on:hover {
  color: rgb(189, 189, 189);
  background-color: #0167c6;
}

.makeStyles-root-103 {
  width: 95vw;
}

.filterModal .traningBox {
  padding-top: 22px;
}

.filterModal .trainingTabs .list ul {
  overflow: hidden;
}

.filterModal .MuiPaper-root.MuiDialog-paper {
  scrollbar-width: "thin ";
  scrollbar-color: transparent transparent;
}

.filterModal .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar {
  width: 0px;
}

.filterModal .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-track {
  background: transparent;
}

.filterModal .MuiPaper-root.MuiDialog-paper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.filterModal li.trainingTab-active {
  border-bottom: none;
}

.filterModal .trainingTabs-nav>li {
  border-bottom: none;
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  width: initial;
  align-items: baseline;
}

.filterModal ul>li.trainingTab-active {
  color: #3888ff !important;
}

.filterModal ul>li:not(.trainingTab-active) {
  color: #a7a7a7 !important;
}

.filterModal .trainingTab-active a:hover {
  color: #3888ff !important;
}

.filterModal .trainingTabs-nav a {
  font-size: 16px;
}

.filterModal .trainingTabs-nav li a {
  padding-left: 0 !important;
}

.filterModal .title-modal {
  font-size: 18px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -0.05px;
}

.filterModal .selectExo {
  display: flex;
  align-items: flex-end;
}

.selectExo-counter {
  background-color: #3888ff;
  color: white;
  border-radius: 10px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  font-weight: 500;
}

.filterModal .tabResult-search {
  scrollbar-width: "thin ";
  scrollbar-color: transparent transparent;
}

.filterModal .tabResult-search::-webkit-scrollbar {
  width: 8px;
}

.filterModal .tabResult-search::-webkit-scrollbar-track {
  background: transparent;
}

.filterModal .tabResult-search::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 5px;
}

.optionsTab-search .MuiTextField-root:focus-visible {
  border: none;
  outline-color: white;
}

.optionsTab-search .MuiOutlinedInput-adornedStart {
  padding-left: 0px;
}

.optionsTab-search .MuiInputBase-input {
  height: 1.5em;
}

.tabResult-select {
  overflow-y: auto;
  max-height: 60vh;
  height: 100%;
}

.tabResult-search {
  overflow-y: auto;
  max-height: 53vh;
  height: 100%;
}

.tab-modal .MuiTab-root {
  min-width: 100px;
}

.tab-modal .MuiTab-wrapper {
  text-transform: initial;
  color: #3888ff;
}

.tab-modal button:not(.Mui-selected) .MuiTab-wrapper {
  color: #9e9e9e;
}

.tab-modal .MuiTabs-indicator {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.trainingModal .trainingBtngroup {
  border: none;
}

.trainingTabs .wrap-search {
  padding: 0px;
  overflow: auto scroll;
  height: 60vh;
  scrollbar-width: "thin ";
  scrollbar-color: transparent transparent;
}

.trainingTabs .wrap-search::-webkit-scrollbar {
  width: 8px;
}

.trainingTabs .wrap-search::-webkit-scrollbar-track {
  background: transparent;
}

.trainingTabs .wrap-search::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 5px;
}

.optionsfilter-wrap>div:first-child {
  overflow-y: auto;
  position: relative;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: 1px solid #aaa;
  background-color: #fff;
  padding: 6px 6px 6px 12px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: #aaa;
  outline: none;
  white-space: nowrap;
}

.optionsfilter-wrap>div:first-child::-webkit-scrollbar {
  display: none;
}

.optionsfilter-wrap>div {
  padding: 6px 20px 6px 12px;
}

.optionsfilter-wrap>div.focus {
  background-color: #e7e7e7;
}

.optionsfilter-popup ul>li.otpgroup {
  padding: 5px;
}

.optionsfilter-popup ul>li.optgroup .label {
  font-weight: 500;
  color: #000000;
}

.optionsfilter-popup ul>li.optgroup>.optgroup-col {
  column-count: 3;
  column-gap: 0px;
  margin-top: 6px;
  margin-bottom: 15px;
}

.optionsfilter-popup ul>li.optgroup>.optgroup-col>li {
  list-style: none;
}

.optionsfilter-popup ul>li.optgroup>.optgroup-col>li label {
  width: 100%;
  display: flex;
  width: max-content;
  align-items: center;
}

.optionsfilter-popup ul>li.optgroup>.optgroup-col>li label>span {
  padding: 4px;
}

.optionsfilter-popup .MuiPopover-paper {
  min-width: 540px;
  padding: 9px !important;
  margin-top: 5px;
}

.optionsExercices {
  >.repsExoBtn {
    background-color: #e4e2e2;

    >span {
      justify-content: center;
      display: flex;
    }
  }

  >.repsExoBtn:hover {
    background-color: #cbcbcb;
  }

  >button {
    padding: 6px 9px;

    >span {
      justify-content: normal;
      text-transform: initial;
    }
  }
}

.checboxExercice svg.MuiSvgIcon-root {
  color: #d1d1d1;
  width: 20px;
  height: 20px;
}

.checboxExercice input {
  width: 73px;
  height: 73px;
}

.MuiTypography-gutterBottom {
  margin-bottom: "0px";
}

.MuiInputBase-input {
  padding: 9px 0 8px !important;
}

.MuiOutlinedInput-input[type="password"],
.MuiOutlinedInput-input[type="email"] {
  padding: 18.5px 14px !important;
}

label+.MuiInput-formControl {
  margin-top: 13px !important;
}

#cardPlanTraining .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense {
  width: 50px;
}

#cardPlanTraining #temps .MuiChip-labelSmall {
  padding-left: 4px;
  padding-right: 8px;
}

#CardFullTraining .MuiGrid-spacing-xs-2 {
  width: auto;
  margin: 0px;
  margin-bottom: 55px;
}

#cardPlanTraining {
  border-bottom: 1px solid #80808054;
}

.audio-recorder {
  margin-bottom: 40px;
}

#audio audio {
  margin-bottom: 20px;
}

#exerciceAudio {
  display: initial !important;
}

#audio-name.MuiInputBase-input.MuiInput-input {
  text-align: center;
}

p#audio-name {
  text-align: center;
}

#optionsModal .MuiInput-underline:before {
  border-bottom: none !important;
}

.buttonAddTraining {
  border-radius: 16px !important;
  padding: 3px 10px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0px !important;
  width: fit-content !important;
}

.buttonAddTraining .MuiButton-startIcon {
  margin-right: 2px;
}

#circuitBloc label.MuiFormLabel-root {
  color: white !important;
}

#circuitBloc .MuiFormControl-root.MuiTextField-root .MuiInputBase-root {
  color: white !important;
  font-weight: 500 !important;
}

#circuitBloc .MuiFormControl-root.MuiTextField-root .MuiInputBase-root::before {
  border-bottom: 1px solid #cdcccc !important;
}

#circuitBloc .MuiFormControl-root.MuiTextField-root .MuiInputBase-root::after {
  border-bottom: 2px solid #cdcccc !important;
}

#titleInput .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #cdcccc !important;
}

#titleInput .MuiInput-underline:before {
  border-bottom: 1px solid #cdcccc !important;
}

#titleInput .MuiInput-underline:after {
  border-bottom: none !important;
}

#titleInput .MuiInputBase-formControl.MuiInput-formControl {
  color: #000000 !important;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  position: absolute;
  width: 410px;
  z-index: 8;
  top: 0;
  left: 0;
}

.banner span {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width: 530px) {
  .filterModal .MuiDialogContent-root {
    width: 85vw !important;
  }

  .filterModal .MuiFormControl-fullWidth {
    width: initial !important;
  }
}

@media screen and (max-width: 615px) {
  .trainingTabs-nav>li {
    width: 33%;
  }

  .trainingBtngroup {
    left: 15px !important;
    right: auto !important;
  }
}

@media screen and (max-width: 1500px) {
  .exerciceAdd {
    .optionsTab-search .MuiInputBase-input {
      height: 0.9em;
    }

    .optionsfilter-wrap>div:first-child {
      padding: 3px 3px 3px 12px;
    }

    .traningBox>div {
      margin-bottom: 10px !important;
    }

    .trainingTabs .list ul,
    .wrappertrainingTabs .list ul {
      min-height: 85vh;
    }

    .tabResult-search {
      max-height: 66vh;
    }
  }
}