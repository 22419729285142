.conversation-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notif-badge {
  background-color: red;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  position: absolute;
  top: -3px;
  right: -14px;
}
.MuiBadge-colorError {
  background-color: #44a1f1 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
}

.notif-badge span {
  color: white !important;
  font-size: 11px !important;
  position: absolute;
  top: -2px;
  right: 3.5px;
}
.conversation-list-item-selected {
  width: 100%;
}
.searchCustomer {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: initial;
    border-width: 1px;
  }
}
