.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 14px;
  cursor: pointer;
  width: 100%; 
  border-bottom: solid 1px #DDD;
  height: 86px;
}

.conversation-list-item:hover {
  background: #eeeef1;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  color: black;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  max-width: 155px;
  /* text-overflow: ellipsis; */
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.conversation-snippet {
  font-size: 12px;
    color: #888;
    margin: 0;
    word-break: break-word;
    width: 210px;
    overflow: hidden;

}
p.conversation-snippet{
  max-height: 32px;

}
.conversation-info span{
  color: #b7b7b7 !important;
}
.conversation-info span.warningTime{
  color: red!important;
}
.conversation-snippet::first-letter{
  text-transform: capitalize;
}

.conversation-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.conversation-list-item-selected {
  background: #2b96f2;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 14px;
  cursor: pointer;
}

.conversation-list-item-selected .conversation-title {
  color: white;
}

.conversation-list-item-selected .conversation-snippet {
  color: white;
}

#popup .MuiButton-root {
  min-width: 0px!important;
  padding: 0px!important;
  width: 6px;
  
}
#popup >.MuiButton-root:hover{
  background-color: transparent;
}

.conversation-list-item-selected .MuiTypography-root{
  color: #E4F3FF!important;
}

.conversation-list-item-selected .conversation-info span{
  color: #dbdbdb !important;
}

