body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.messenger {
  display: flex;
  width: 100%;
  height: calc(100vh - 45px);
  background: #eeeef1;
  position: relative;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-x: auto;
  
}

.scrollable::-webkit-scrollbar {
  background-color: transparent;
width: 0px;
}

.scrollable::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}
.sidebar {
  background: white;
  border-right: solid 3px #d7d7d7;
}
#scrollableListconvestation {
  scroll-behavior: smooth;
}
.content {
  background: white;
  width: 0px;
}
.content{
  scrollbar-width: "thin ";
  scrollbar-color: transparent transparent;
}
.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-track {
  background: transparent;
}

.content::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

.footer {
  background: white;
  border-left: solid 3px #d7d7d7;
}

@media screen and (max-width: 1200px) {
  .sidebar {
    position: absolute;
    left: -313px;
    top: 0;
    z-index: 8;
    height: 100%;
    transition: left 0.4s ease;
  }

  .sidebar.open {
    left: 0;
  }

  .footer {
    display: none;
  }
}