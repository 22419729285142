.card {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 8px;
  width: 100%;
  background-color: white;
  height: 90px;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

#groupButton {
  padding-right: 5px;
  display: flex;
  flex-direction: column;
}

#groupButton .MuiButton-root {
  min-width: 300px !important;
}

#warning-call {
  padding: 3px;
  display: flex;
  background-color: #ffffffcd;
}

#warning-call img {
  margin-left: 15px;
}

.line-through {
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: #9f9f9f;
}

.taskList-btn {
  text-transform: initial;
  width: 100%;
  justify-content: space-between;
  border-radius: 0% !important;
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid #eeeeee !important;
  border-top: 1px solid #eeeeee !important;

  >button {
    background-color: #ffffffcd !important;
    padding: 4px !important;
    font-size: 13px !important;
  }
}

.taskList-btn .MuiButton-contained:hover {
  box-shadow: none !important;
}

.taskList-btn .MuiButton-contained {
  box-shadow: none !important;
}

.taskList-btn span.MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin-left: 10px;
}

.taskList .MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-left: 2px;
}

.taskList-task {
  background-color: #151515;
  display: flex;
  border-top: 1px solid #e1e1e1 !important;
}

.taskList-task.edit {
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid #eeeeee;
}

.taskList-task .MuiFormControlLabel-root .MuiFormControlLabel-root {
  width: 16px !important;
  height: 16px !important;
}

.taskList-task .MuiCheckbox-root {
  color: #bcbcbc;
}

.taskList-task .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.taskList-task.edit .MuiSvgIcon-root {
  width: 19px;
  height: 19px;
  padding: 1.5px;
  border-radius: 15%;
}

.taskList-task.edit #sendicon .MuiSvgIcon-root {
  color: #ededed;
  background-color: #3f51b5;
}

.taskList-task.edit #closeicon .MuiSvgIcon-root {
  color: #707070;
  background-color: #afafaf;
}

.taskList-task #moreicon .MuiSvgIcon-root {
  color: #ededed;
}

.taskList-button button {
  padding: 10px;
}

.taskList-button button:hover {
  background-color: transparent;
}

.taskList-button #closeicon {
  padding-right: 0px;
}

.taskList-task .MuiCheckbox-colorSecondary.Mui-checked {
  color: #8c8c8c;
}

.taskList-task label.MuiFormControlLabel-root {
  margin-right: 0px;
  margin-left: 0px;
  align-items: flex-start;
}

.taskList-task .MuiTextField-root {
  justify-content: center;
  width: 100%;
  margin-right: 10px;
}

.taskList-task .MuiTextField-root>div {
  color: #ededed;
  display: flex;
  font-size: 12px;
}

.taskList-task.edit .MuiTextField-root>div {
  color: #141414;
  display: flex;
  padding: 6px 0px 6px;
  font-size: 12px;
  margin-left: 15px;
}

.taskList-task .MuiTextField-root.line-through>div {
  color: #9f9f9f;
  display: flex;
  font-size: 12px;
}

.taskList-task .MuiTextField-root .MuiInput-underline:before {
  border: none !important;
}

.taskList-task .MuiTextField-root .MuiInput-underline:after {
  border: none !important;
}

.taskList-task .MuiTextField-root>div textarea {
  scrollbar-width: "thin";
  scrollbar-color: #bcbcbc #bcbcbc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.taskList-task .MuiTextField-root>div textarea::-webkit-scrollbar {
  width: 8px;
}

.taskList-task .MuiTextField-root>div textarea::-webkit-scrollbar-track {
  background: transparent;
}

.taskList-task .MuiTextField-root>div textarea::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}

/*NOTE*/
.noteList-note {
  .taskList-button {
    position: absolute;
    right: 0;
  }

  .MuiTextField-root {
    justify-content: center;
    width: 100%;
    margin: 0 10px 0 10px;

  }

  .MuiOutlinedInput-multiline {
    padding: 1.5px 20px 1.5px 10px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #151515;
    border-width: 1px;
  }
}

.noteList-note .MuiTextField-root>div {
  color: black;
  display: flex;
}

#note-speparator {
  margin-bottom: 15px;
}

/*TOOLBAR*/
#groupbtn span.MuiButton-label {
  letter-spacing: 2px;
  font-size: 12px;
}

@media screen and (max-width: 920px) {
  .makeStyles-root-14 {
    width: 80%;
  }

  .makeStyles-root-11 .MuiContainer-root {
    padding-left: 8px;
  }

  .makeStyles-root-14 #toolbargroup {
    width: 100%;
  }

  #CustomerInfo {
    display: none;
  }

  .makeStyles-root-14 #toolbargroup button {
    font-size: 7px;
    padding: 6px 9px;
  }

  #leftbtnToolbar {
    display: none;
  }

  #ContainerToolbar {
    justify-content: flex-start !important;
  }

  .MuiButtonGroup-grouped {
    min-width: 14.4% !important;
  }
}

/**/
@media screen and (max-width: 500px) {
  .makeStyles-root-24 {
    width: 96vw !important;
  }
}