.audio-player {
  margin-left:10px;
  --player-button-width: 3em; 
  --sound-button-width: 2em;
  --space: .5em;
  --max_height: 20px;
}
.audio-player .MuiSvgIcon-root {
  width: 15px;
  height: 20px;
}


.audio-player .MuiIconButton-label { 
    justify-content: space-between;
}
/* .audio-player {

  --sound-button-width: 2em;
  --space: .5em;
  width: 15rem;
  height: 15rem;
}
  */
  
.audio-icon {
    width: 90%;
    height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px; 
}

.player-button {
  background-color: transparent;
  border: 0;
  width:  20px;
  height:  var(--max_height);
  cursor: pointer;
  padding: 0;
}
#current-time{
  color: white;
  font-size: 11px;
  font-weight: 600;
}
.optionAudio:hover{
  cursor: pointer;
}
   
   