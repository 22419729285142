.message {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 18px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 18px;
  margin-bottom: 10px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 18px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 18px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #eaeaea;
  padding: 10px 15px;
  border-radius: 18px;
  max-width: 400px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  text-overflow: ellipsis;
  position: relative;
}

.message.mine .bubble-container .bubble {
  background: #1d1d1d;
  color: white;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* On bubble over change className="summarize-button" */
.summarize-button {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 12;
}

.message .bubble-container .bubble:hover .summarize-button {
  opacity: 1;
}

.image-preview {
  width: 40vw !important;
  max-width: 250px !important;
  display: block;
}

.avatar-preview {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 16px;
  object-fit: cover;
  display: block;
}

.video-preview {
  width: 40vw !important;
  max-width: 250px !important;
  display: block;
}

.message.start.end:first-child .bubble-container:first-child {
  margin-bottom: 15px;
}

@media screen and (max-width: 959px) {
  .message .bubble-container .bubble {
    max-width: 75vw;
  }
}
