.compose {
  padding: 10px 10px 20px 10px;
  display: flex;
  align-items: flex-end;
 
  width: calc(100vw - 641px);
  min-width: 428px;

  background-color: white;
}
.wrapper-compose{
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.compose-input {
  display: block;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border-radius: 6px;
  border: 0;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #ebebeb;
  resize: none;
}

.compose-input{
  scrollbar-width: "thin ";
  scrollbar-color: transparent transparent;
}
.compose-input::-webkit-scrollbar {
  width: 8px;
}

.compose-input::-webkit-scrollbar-track {
  background: transparent;
}

.compose-input::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
}
.compose-input::placeholder {
  opacity: 0.3;
}

.compose-input:focus {
  outline: none;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

@media screen and (max-width: 1200px) {
  .compose,.wrapper-compose {
    width: 100%;
  }
}
